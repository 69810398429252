import { Injectable } from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Auth } from 'aws-amplify';
import { BehaviorSubject, catchError, EMPTY, from, iif, map, of, startWith, switchMap } from 'rxjs';
import { NAVIGATION } from '@constants/navigation';

@Injectable()
export class AuthService {
  constructor(private _authenticator: AuthenticatorService) {
    this._authenticator.subscribe(service => {
      const authenticated = service.authStatus == 'authenticated';
      this.authenticated$.next(authenticated);
    });
  }

  authenticated$ = new BehaviorSubject<boolean>(false);

  async signOut() {
    return Auth.signOut();
  }

  async getToken() {
    const currentSession = await Auth.currentSession();
    return currentSession.getAccessToken().getJwtToken();
  }

  async isValidSession() {
    const currentSession = await Auth.currentSession();
    return currentSession.isValid();
  }

  getGroups$ = this.authenticated$.pipe(
    switchMap(authenticated =>
      iif(
        () => authenticated,
        from(Auth.currentSession()).pipe(
          map(curr => {
            const payload = curr.getAccessToken().decodePayload();
            const groups = payload['cognito:groups'] as string[] | undefined;
            return groups ? groups : [];
          })
        ),
        EMPTY
      )
    )
  );

  isAdmin$ = this.getGroups$.pipe(
    switchMap(groups => {
      return of(groups && groups.indexOf('admin') > -1);
    })
  );

  navigation$ = this.getGroups$.pipe(
    startWith([] as string[]),
    switchMap(groups => {
      const clone = [...NAVIGATION];
      const filtered = clone.filter(n => {
        if (n.groups) {
          return n.groups.every(g => groups && groups.indexOf(g) > -1);
        } else {
          return true;
        }
      });

      return of(filtered);
    }),
    catchError(err => {
      console.error(err);
      return of(err);
    })
  );
}
