import { Component, Input, ViewChild } from '@angular/core';
import { NemChart } from '../../services/nem-chart';
import { ChartComponent, ChartType } from 'ng-apexcharts';
import { takeSnapshot } from '../../services/take-snapshot';

@Component({
  selector: 'nem-chart',
  templateUrl: './nem-chart.comp.html',
  styles: [
    `
      ::ng-deep .snapshot-button {
        width: 1rem !important;
        height: 1rem !important;
        padding: 0.75rem !important;
      }
    `
  ]
})
export class NemChartComponent {
  @Input() nemChart!: NemChart;

  dialogVisible = false;

  @ViewChild('plot') plot!: ChartComponent;
  @ViewChild('plot_brush') plot_brush!: ChartComponent;

  resizing = false;

  capturePending = false;

  toggleTableView() {
    this.nemChart.tabular.toggle();
    this.refresh();
  }

  chartTypeChange(chartType: ChartType) {
    this.nemChart.chartType = chartType;
    this.nemChart.refereshFromCache('user');
  }

  async takeSnapshot(text: string | undefined, containerId: string) {
    this.capturePending = true;
    await takeSnapshot({ text: text || '', containerId: containerId });
    this.capturePending = false;
  }

  onResizeStart() {
    this.resizing = true;
  }

  onResizeEnd() {
    this.resizing = false;
    this.refresh();
  }

  refresh() {
    if (this.plot) this.plot.resetSeries();
    if (this.plot_brush) this.plot_brush.resetSeries();
  }
}
