<ul class="flex gap-2">
  <li class="mt-2">
    <h5 class="mb-1 font-semibold">Primary source/technology type</h5>
    <ul class="space-y-6 lg:space-y-2 border-slate-100 dark:border-slate-700">
      <li class="text-xs block text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300">
        <p-multiSelect
          (ngModelChange)="modelOnChange($event, 'fuelSources')"
          (onPanelHide)="fixbodyscroll('onhide', 'fuelSources')"
          (onPanelShow)="fixbodyscroll('onshow', 'fuelSources')"
          [(ngModel)]="filtersService.selectedFuelSources"
          [filter]="false"
          [options]="$any(fuelSourcesOptions$ | async)"
          appendTo="body"
          defaultLabel="Select a Fuel source"
          display="chip"
          styleClass="w-[14rem]">
        </p-multiSelect>
      </li>
    </ul>
  </li>
  <ng-container *ngIf="filtersToShow.includes('advanced:full')">
    <li class="mt-2">
      <h5 class="mb-1 font-semibold">
        Stations
        <ng-container *ngIf="stationDates$ | async as dates">
          <span
            class="mx-2 text-sm border border-indigo-100 px-2 py-1 rounded-2xl"
            [escape]="false"
            [pTooltip]="stationsDateInfo">
            {{ dates.min | date : 'dd/MM/yyyy' }} - {{ dates.max | date : 'dd/MM/yyyy' }}
          </span>
        </ng-container>
      </h5>
      <ul class="space-y-6 lg:space-y-2 border-slate-100 dark:border-slate-700">
        <li class="text-xs block text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300">
          <p-multiSelect
            (ngModelChange)="modelOnChange($event, 'stations')"
            (onPanelHide)="fixbodyscroll('onhide', 'stations')"
            (onPanelShow)="fixbodyscroll('onshow', 'stations')"
            [(ngModel)]="filtersService.selectedStations"
            [filter]="true"
            [options]="$any(stationOptions$ | async)"
            appendTo="body"
            dataKey="name"
            defaultLabel="Select stations"
            display="chip"
            optionLabel="name"
            styleClass="w-[17rem]">
            <ng-template let-station pTemplate="item">
              <div class="flex items-center gap-2 w-full">
                <div class="flex flex-col gap-2">
                  <div class="font-bold text-slate-600">
                    {{ station.name }}
                    <span class="ml-2 text-sm border border-indigo-100 px-2 py-1 rounded-2xl">
                      {{ station.last_changed | date : 'dd/MM/yyyy' }}
                    </span>
                  </div>

                  <div class="flex gap-2">
                    <span
                      class="bg-indigo-50 text-sm text-indigo-600 border border-indigo-100 px-2 py-1 rounded-2xl"
                      *ngFor="let duid of station.duids">
                      {{ duid.name }}
                    </span>
                  </div>
                </div>
                <div class="ml-auto">
                  <p-selectButton
                    (onChange)="stationsOnChange($event)"
                    (onOptionClick)="stationsOnChange($event)"
                    *ngIf="station.duids.length > 1"
                    [(ngModel)]="station.grouped"
                    [disabled]="!filtersService.isSelected(station.name)"
                    [options]="[
                      { label: 'Grouped', value: true },
                      { label: 'Individual', value: false }
                    ]"
                    optionLabel="label"
                    optionValue="value"></p-selectButton>
                </div>
              </div>
            </ng-template>
          </p-multiSelect>
        </li>
      </ul>
    </li>

    <li class="mt-6">
      <ul class="mt-5 space-y-6 lg:space-y-2 border-slate-100 dark:border-slate-700">
        <li class="flex align-items-center gap-1">
          <p-checkbox [(ngModel)]="filtersService.aggrByFuelSources" [binary]="true" inputId="aggrByFuelSources">
          </p-checkbox>
          <!--suppress XmlInvalidId -->
          <label for="aggrByFuelSources">Aggregate by primary source</label>
        </li>
      </ul>
    </li>
  </ng-container>
</ul>
