import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SplitterModule } from 'primeng/splitter';
import { ColorPickerModule } from 'primeng/colorpicker';
import { SliderModule } from 'primeng/slider';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { PanelModule } from 'primeng/panel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';

import { NgApexchartsModule } from 'ng-apexcharts';

import { TruncatePipe } from './pipes/truncate';
import { NumberFormatterPipe } from './pipes/number-formatter';

import { NemChartComponent } from './components/nem-chart/nem-chart.comp';
import { NemChartToolbarComponent } from './components/nem-chart-toolbar/nem-chart-toolbar.comp';
import { NemTabularComponent } from './components/nem-tabular/nem-tabular.comp';
import { CardComponent } from './components/layout/nem-card.comp';
import { NemShimmerComponent } from './components/layout/nem-shimmer.comp';
import { NemPlotComponent } from './components/layout/nem-plot.comp';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  SplitterModule,
  ColorPickerModule,
  SliderModule,
  SkeletonModule,
  TableModule,
  DynamicDialogModule,
  PanelModule,
  SelectButtonModule,
  SidebarModule,
  DialogModule,
  TooltipModule,
  OverlayPanelModule,
  ButtonModule,
  NgApexchartsModule,
  CheckboxModule,
  DropdownModule
];

const components = [
  NemChartToolbarComponent,
  NemChartComponent,
  NemTabularComponent,
  NemShimmerComponent,
  CardComponent,
  NemPlotComponent
];

const pipes = [TruncatePipe, NumberFormatterPipe];

@NgModule({
  declarations: [...components, ...pipes],
  imports: [...modules],
  exports: [...modules, ...components, ...pipes]
})
export class NemChartModule {}
