<div class="max-w-8xl mx-auto border-b-neutral-100 border-b bg-white">
  <div class="py-2 border-b border-slate-900/10 lg:px-8 lg:border-0 lg:mx-0">
    <div class="relative flex items-center">
      <a class="mr-3 flex w-auto">
        <img class="w-auto h-9" ngSrc="./assets/images/vepc-logo.png" alt="vepc-logo" height="123" width="372" />
        <!-- <img class="w-auto h-5" src="./assets/images/victoria_uni.png" alt="victoria-logo"> -->
      </a>

      <div class="ml-3 text-xl leading-5 antialiased font-bold text-indigo-700 py-1 px-3 hidden xl:flex items-center">
        <nem-logo></nem-logo>
      </div>

      <div class="relative hidden lg:flex items-center ml-auto">
        <nav class="text-lg leading-6 font-semibold text-slate-500">
          <ul class="flex space-x-8">
            <li *ngFor="let nav of navigation$ | async">
              <a
                class="hover:text-indigo-500"
                [routerLink]="nav.routerLink"
                routerLinkActive="text-indigo-500"
                [pTooltip]="nav.tooltip"
                [routerLinkActiveOptions]="{ exact: true }">
                {{ nav.label }}
              </a>
            </li>

            <li *ngIf="authenticated$ | async">
              <amplify-authenticator>
                <ng-template amplifySlot="authenticated" let-signOutFn="signOut">
                  <a class="hover:text-indigo-500 cursor-pointer" (click)="signOut(signOutFn)">Sign Out</a>
                </ng-template>
              </amplify-authenticator>
            </li>
          </ul>
        </nav>
      </div>

      <div class="ml-auto -my-1 lg:hidden">
        <button
          (click)="openSidebar()"
          type="button"
          class="text-slate-500 w-8 h-8 flex items-center justify-center hover:text-slate-600">
          <svg width="24" height="24">
            <path
              d="M5 6h14M5 12h14M5 18h14"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>

<p-sidebar appendTo="body" *ngIf="sidebarVisible" [(visible)]="sidebarVisible">
  <nav id="nav" class="lg:text-sm lg:leading-6 relative">
    <div class="sticky top-0 -ml-0.5 pointer-events-none">
      <div class="h-10 bg-slate-50"></div>
      <div class="h-8 bg-gradient-to-b from-slate-50"></div>
    </div>
    <ul>
      <li *ngFor="let nav of navigation$ | async">
        <a
          [routerLink]="nav.routerLink"
          routerLinkActive="text-indigo-500 dark:text-indigo-400"
          [routerLinkActiveOptions]="{ exact: true }"
          class="group flex items-center lg:text-sm lg:leading-6 mb-4 font-medium text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300">
          <div
            class="mr-4 rounded-md ring-1 ring-slate-900/5 shadow-sm group-hover:shadow group-hover:ring-slate-900/10 dark:ring-0 dark:shadow-none dark:group-hover:shadow-none dark:group-hover:highlight-white/10 group-hover:shadow-purple-200 dark:group-hover:bg-purple-400 dark:bg-slate-700 dark:highlight-white/5">
            <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none">
              <path
                d="M6 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8ZM6 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1Z"
                class="fill-purple-400 group-hover:fill-purple-500 dark:group-hover:fill-purple-300 dark:fill-slate-500"></path>
              <path
                d="M13 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V8Z"
                class="fill-purple-200 group-hover:fill-purple-300 dark:group-hover:fill-white dark:fill-slate-300"></path>
              <path
                d="M13 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-1Z"
                class="fill-purple-400 group-hover:fill-purple-500 dark:group-hover:fill-purple-300 dark:fill-slate-500"></path>
            </svg>
          </div>
          <span class=""> {{ nav.label }} </span>
        </a>
      </li>
    </ul>
  </nav>
</p-sidebar>
