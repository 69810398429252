import { Frequency } from '../../dtos/filters/frequency.dto';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import 'dayjs/locale/en-au';

dayjs.locale('en-au');

dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(LocalizedFormat);

const DATE_FORMAT = 'DD/MM/YYYY';

export const DEFAULT_RANGE: [string, string] = [
  dayjs().subtract(2, 'w').format(DATE_FORMAT),
  dayjs().format(DATE_FORMAT)
];

export function dateFormatter(frequency: Frequency, options?: { shortFormat: boolean }) {
  return function (val: number | string | undefined) {
    const t = dayjs(val);
    const short = options?.shortFormat;

    switch (frequency) {
      case Frequency.MONTHLY:
        return t.format(short ? 'MMM' : 'MMM YYYY');
      case Frequency.QUARTERLY:
        return short ? `Q${t.quarter()}` : `Q${t.quarter()} ${t.format('YYYY')}`;
      case Frequency.YEARLY:
        return t.format('YYYY');
      case Frequency.DAILY:
      case Frequency.WEEKLY:
        return t.format('ddd, MMM D, YYYY');
      default:
        return t.format('ddd, MMM D, YYYY HH:mm');
    }
  };
}

export function updateRange([fromDate, toDate]: (Date | string)[], modelValue: string | Date, model: string) {
  if (model === 'from') {
    if (dayjs(modelValue, DATE_FORMAT) > dayjs(toDate, DATE_FORMAT)) {
      toDate = dayjs(modelValue, DATE_FORMAT).format(DATE_FORMAT);
    }
  }

  if (model === 'to') {
    if (dayjs(modelValue, DATE_FORMAT) < dayjs(fromDate, DATE_FORMAT)) {
      fromDate = dayjs(modelValue, DATE_FORMAT).format(DATE_FORMAT);
    }
  }

  return [fromDate, toDate];
}

export function getStartEndDate(dates: Date[]): {
  min?: number;
  max?: number;
} {
  if (!dates.length) return {};
  return {
    min: dayjs(dates[0]).toDate().getTime(),
    max: dayjs(dates[dates.length - 1])
      .toDate()
      .getTime()
  };
}
