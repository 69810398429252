<ul class="bg-white flex items-end flex-wrap gap-x-3 gap-y-1">
  <ng-content></ng-content>

  <li class="mt-2" *ngIf="filtersToShow.includes('regions')">
    <h5 class="mb-1 font-semibold">
      Regions:
      <i
        class="pi pi-info-circle"
        [pTooltip]="netInterconnectorsInfo"
        [escape]="false"
        *ngIf="serviceName === 'regional'"></i>
    </h5>
    <ul class="space-y-6 lg:space-y-2">
      <li class="text-xs block text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300">
        <p-multiSelect
          (ngModelChange)="modelOnChange($event, 'regions')"
          (onPanelHide)="fixbodyscroll('onhide', 'regions')"
          (onPanelShow)="fixbodyscroll('onshow', 'regions')"
          [(ngModel)]="selectedRegions"
          [filter]="false"
          [options]="regions"
          appendTo="body"
          defaultLabel="Select a Region"
          display="chip"
          optionLabel="name"
          optionValue="id"
          styleClass=" w-[12rem]">
        </p-multiSelect>
      </li>
    </ul>
  </li>

  <li class="mt-2" *ngIf="filtersToShow.includes('single_region')">
    <h5 class="mb-1 font-semibold">Region</h5>
    <ul class="space-y-6 lg:space-y-2">
      <li class="text-xs block text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300">
        <p-dropdown
          (ngModelChange)="modelOnChange($event, 'region')"
          (onHide)="fixbodyscroll('onhide', 'region')"
          (onShow)="fixbodyscroll('onshow', 'region')"
          [(ngModel)]="selectedRegion"
          [filter]="false"
          [options]="regions"
          appendTo="body"
          defaultLabel="Select a Region"
          display="chip"
          optionLabel="name"
          optionValue="id"
          styleClass=" w-[12rem]">
        </p-dropdown>
      </li>
    </ul>
  </li>

  <nem-advanced-filters
    *ngIf="filtersToShow.includes('advanced')"
    [filtersToShow]="filtersToShow"></nem-advanced-filters>

  <li class="mt-2" *ngIf="filtersToShow.includes('interconnectors')">
    <h5 class="mb-1 font-semibold">
      Interconnectors:
      <i class="pi pi-info-circle" [pTooltip]="interconnectorsInfo" [escape]="false" *ngIf="viewMode === 'single'"></i>
    </h5>
    <ul class="space-y-6 lg:space-y-2">
      <li class="text-xs block text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300">
        <p-multiSelect
          (ngModelChange)="modelOnChange($event, 'interconnectors')"
          (onPanelHide)="fixbodyscroll('onhide', 'interconnectors')"
          (onPanelShow)="fixbodyscroll('onshow', 'interconnectors')"
          [(ngModel)]="selectedInterconnectors"
          [filter]="false"
          [options]="interConnectors"
          appendTo="body"
          defaultLabel="Select an Interconnector"
          display="chip"
          optionLabel="name"
          optionValue="id"
          styleClass="w-[12rem]">
        </p-multiSelect>
      </li>
    </ul>
  </li>

  <li class="mt-2" *ngIf="filtersToShow.includes('range')">
    <h5 class="mb-1 font-semibold">
      Date range:
      <i *ngIf="viewMode === 'single'" [escape]="false" [pTooltip]="dateRangeInfo" class="pi pi-info-circle"></i>
    </h5>
    <ul class="space-y-6 lg:space-y-2">
      <li class="text-sm flex gap-3 text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300">
        <p-calendar
          (ngModelChange)="modelOnChange($event, 'from')"
          [(ngModel)]="range[0]"
          appendTo="body"
          dataType="string"
          dateFormat="dd/mm/yy"
          inputId="from"
          styleClass="nem-calender w-[8rem]	 "></p-calendar>

        <p-calendar
          (ngModelChange)="modelOnChange($event, 'to')"
          [(ngModel)]="range[1]"
          appendTo="body"
          dataType="string"
          dateFormat="dd/mm/yy"
          inputId="to"
          styleClass="nem-calender w-[8rem]"></p-calendar>
      </li>
    </ul>
  </li>

  <li class="mt-2" *ngIf="filtersToShow.includes('frequency')">
    <h5 class="mb-1 font-semibold">Frequency:</h5>
    <ul class="space-y-6 lg:space-y-2">
      <li class="text-sm block text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300">
        <p-dropdown
          (ngModelChange)="modelOnChange($event, 'frequency')"
          (onHide)="fixbodyscroll('onhide', 'frequency')"
          (onShow)="fixbodyscroll('onshow', 'frequency')"
          [(ngModel)]="frequency"
          [options]="dataFrequency"
          [showClear]="false"
          appendTo="body"
          class="leading-1"
          optionLabel="name"
          optionValue="id"
          placeholder="Select a frequency"
          styleClass="w-[12rem] "></p-dropdown>
      </li>
    </ul>
  </li>

  <li class="mt-4">
    <button
      (click)="filtersSubmit($event)"
      [disabled]="false"
      [loading]="loading"
      class="p-button w-full"
      label="Load data"
      pButton></button>
  </li>
</ul>
