import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { Observable, catchError, throwError } from 'rxjs';

export class ErrorInterceptor implements HttpInterceptor {
  constructor(private _messageService: MessageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let message = '';

        if (error.error instanceof ErrorEvent) {
          message = error.error.message;
        } else {
          const customError = error.error as { code: number; name: string; description: string };
          message = `Code: ${error.status}\n Message: ${customError.description}`;
        }

        this._messageService.add({ severity: 'error', summary: 'Error', detail: message });
        return throwError(() => new Error(message));
      })
    );
  }
}
