import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'nem-logo',
  template: `
    <svg [attr.width]="width" height="100%" viewBox="0 0 370 83.93145772289529">
      <g
        transform="matrix(1.1111078144689344,0,0,1.1111078144689344,-5.555718150657207,-13.590482019477788)"
        class="logo-fill">
        <path
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M37.854,26.105c4.419,0.427,9.428,0.088,13.873,1.506  c9.941,3.172,12.788,12.875,6.951,21.616c-5.842,8.748-19.074,12.679-28.707,8.529c-5.733-2.47-8.564-7.035-8.386-13.493  c0.031-1.115,0.004-2.23,0.004-3.859c-1.384,1.08-2.314,2.11-3.474,2.62c-1.036,0.456-2.631,0.83-3.42,0.339  c-0.834-0.521-1.51-2.167-1.378-3.21c0.498-3.95,2.573-7.114,5.903-9.304c2.276-1.498,4.431-0.314,4.684,2.432  c0.073,0.803,0.011,1.618,0.011,2.586c3.181-2.606,6.212-5.089,9.882-8.096c-1.812,0.348-2.923,0.822-3.96,0.692  c-1.139-0.141-2.447-0.605-3.204-1.391c-0.354-0.366,0.118-2.113,0.728-2.727c3.469-3.495,7.695-5.516,12.689-5.434  c2.33,0.038,3.104,1.875,1.559,3.693C40.347,24.094,38.744,25.293,37.854,26.105z"></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M67.94,49.245c6.422,0.004,9.633,4.886,6.659,10.807  c-3.024,6.023-8.019,9.765-15.011,9.989c-6.783,0.217-9.113-4.218-6.999-10.354C54.57,53.937,61.87,49.242,67.94,49.245z"></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M74.326,45.595c-5.795-0.04-10.959-5.349-10.944-11.251  c0.01-4.357,3.075-7.577,7.186-7.55c5.739,0.039,11.674,6.009,11.645,11.717C82.191,42.906,79.158,45.627,74.326,45.595z"></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M36.197,75.248c-2.691-0.746-5.478-1.256-8.046-2.303  c-2.54-1.036-4.417-3.082-4.375-6.015c0.041-2.863,2.01-4.553,4.556-5.425c4.971-1.703,9.95-1.554,14.65,0.823  c2.614,1.323,4.723,3.389,4.095,6.751c-0.636,3.414-3.49,4.53-6.349,5.351c-1.381,0.397-2.913,0.271-4.377,0.383  C36.3,74.958,36.249,75.102,36.197,75.248z"></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M88.205,52.5c5.772-0.035,8.319,4.093,5.863,8.747  c-2.173,4.12-7.616,6.623-11.453,5.267c-3.709-1.311-5.093-4.566-3.344-8.096C81.184,54.559,84.474,52.786,88.205,52.5z"></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M46.266,87.77c-2.305-0.647-4.112-0.884-5.658-1.646  c-3.387-1.671-3.609-5.446-0.465-7.569c3.783-2.554,7.987-2.739,12.106-1.13c3.565,1.392,4.038,5.329,0.839,7.496  C51.013,86.325,48.388,86.917,46.266,87.77z"></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M57.453,27.11c-2.69-0.852-5.713-1.424-8.362-2.749  c-2.986-1.494-2.787-4.063,0.091-5.745c3.72-2.173,12.656-1.704,16.048,0.843c2.476,1.857,2.406,4.397-0.435,5.661  C62.648,26.075,60.217,26.391,57.453,27.11z"></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.009,56.955c0.223,2.711-0.844,4.873-3.298,5.907  c-2.602,1.095-4.785-0.288-6.373-2.243c-2.035-2.506-3.362-5.387-2.407-8.749c1.258-4.428,5.612-5.746,8.71-2.37  c1.798,1.958,2.784,4.662,4.135,7.029C23.521,56.671,23.265,56.813,23.009,56.955z"></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M75.446,15.578c2.298,1.132,4.353,1.767,5.882,3.037  c0.915,0.759,1.645,2.734,1.288,3.751c-0.365,1.041-2.194,2.245-3.323,2.188c-1.999-0.1-4.194-0.742-5.84-1.851  c-1.147-0.772-2.348-2.96-2.011-4.048C71.828,17.411,73.889,16.688,75.446,15.578z"></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.589,70.245c-0.965,1.368-1.612,3.255-2.71,3.568  c-1.08,0.307-3.025-0.646-3.847-1.653c-1.048-1.285-1.825-3.18-1.823-4.81c0.002-1.304,1.066-3.109,2.191-3.732  c0.768-0.426,2.856,0.455,3.629,1.366C15.165,66.325,15.651,68.213,16.589,70.245z"></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M45.207,17.008c-1.104-0.705-2.2-1.406-3.298-2.105  c1.098-0.819,2.091-2.043,3.317-2.354c1.657-0.42,3.54-0.396,5.239-0.088c1.749,0.317,2.037,1.67,0.427,2.551  C49.295,15.886,47.424,16.257,45.207,17.008z"></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.555,41.71c-0.106,3.194-2.276,6.416-3.938,6.2  c-0.639-0.083-1.572-1.251-1.612-1.966c-0.132-2.368,2.467-5.919,4.086-5.665C9.78,40.388,10.317,41.462,10.555,41.71z"></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.22,28.43c-0.552-1.517-1.03-2.235-0.991-2.923  c0.028-0.5,0.647-1.162,1.165-1.403c1.829-0.85,3.725-1.549,5.596-2.307c0.114,0.565,0.515,1.45,0.297,1.645  C23.54,25.011,21.674,26.448,19.22,28.43z"></path>
      </g>
      <g
        transform="matrix(3.4520855103325,0,0,3.4520855103325,117.23833257938358,-0.18917492859688423)"
        class="logo-fill">
        <path
          d="M7.1 20 l-6.3 -15.6 l4.36 0 l3.92 10.48 l3.94 -10.48 l4.36 0 l-6.32 15.6 l-3.96 0 z M18.98 15.36 l0 -3.74 l6.06 0 l0 3.74 l-6.06 0 z M26.64 20 l0 -15.6 l3.4 0 l6.96 8.72 l0 -8.72 l3.98 0 l0 15.6 l-3.38 0 l-6.96 -8.7 l0 8.7 l-4 0 z M42.58 20 l0 -15.6 l11.98 0 l0 3.88 l-7.98 0 l0 2.24 l5.62 0 l0 3.62 l-5.62 0 l0 2 l7.98 0 l0 3.86 l-11.98 0 z M56.16 20 l0 -15.6 l4.02 0 l4.5 10.68 l4.52 -10.68 l4.02 0 l0 15.6 l-3.98 0 l0 -7.34 l-3.24 7.34 l-2.62 0 l-3.24 -7.34 l0 7.34 l-3.98 0 z"></path>
      </g>
    </svg>
  `,
  styles: [
    `
      .logo-fill {
        fill: #1c1c52;
      }
    `
  ]
})
export class NemLogoComponent {
  @HostBinding('class') classname = 'logo-container';

  @Input() width = 160;
}
