import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'nem-top-menu',
  templateUrl: './top-menu.comp.html'
})
export class TopMenuComponent {
  constructor(private _authService: AuthService, private _router: Router) {}

  @HostBinding('class') classname =
    'sticky top-0 w-full flex-none transition-colors duration-500  lg:border-b lg:border-slate-900/10 bg-white/95';

  socialAnchorClass = 'ease-in duration-150 hover:rounded-md hover:bg-sky-100';

  socialIconClass = 'max-h-8';

  socialLinks = [
    {
      link: 'https://twitter.com/vepc_vu',
      iconSrc: 'assets/images/icons.svg#twitter',
      iconAlt: 'twitter-icon'
    },
    {
      link: 'https://www.facebook.com/VEPCENTRE/',
      iconSrc: 'assets/images/icons.svg#facebook',
      iconAlt: 'facebook-icon'
    },
    {
      link: 'https://www.youtube.com/channel/UCRUxTAPBO0LebQuND_fZerg/videos?',
      iconSrc: 'assets/images/icons.svg#youtube',
      iconAlt: 'youtube-icon'
    }
  ];

  navigation$ = this._authService.navigation$;

  authenticated$ = this._authService.authenticated$;

  sidebarVisible = false;

  openSidebar() {
    this.sidebarVisible = !this.sidebarVisible;
  }

  async signOut(signOutFunc: () => void) {
    signOutFunc();
    await this._router.navigate(['/']);
  }
}
