<p-table
  #table
  [loading]="loading"
  dataKey="id"
  [columns]="tabular.columns"
  [virtualScroll]="true"
  [styleClass]="tabular.className"
  [responsive]="true"
  [rows]="10"
  [value]="$any(tabular.data | async)"
  [resizableColumns]="true"
  columnResizeMode="expand"
  [scrollable]="true"
  [virtualScrollOptions]="{
    numToleratedItems: 30,
    delay: 10,
    scrollHeight: '500px'
  }"
  scrollHeight="500px">
  <ng-template pTemplate="emptymessage"> <p class="p-4 m-auto">No results found.</p> </ng-template>
  <ng-template pTemplate="summary">
    <div class="flex text-slate-500 gap-2 text-xs">
      <div class="flex items-center">
        <span class="mr-2">Colors settings:</span>
        <p-colorPicker
          class="text-center mr-2"
          title="Highlight color"
          appendTo="body"
          [(ngModel)]="tabular.colorGenerator.accentColor"></p-colorPicker>
        <p-colorPicker
          title="Minimum value color"
          class="text-center mr-2"
          appendTo="body"
          [(ngModel)]="tabular.colorGenerator.minColor"></p-colorPicker>
        <p-slider
          title="Balance stepper"
          class="w-[6rem] text-center mr-2"
          [(ngModel)]="tabular.colorGenerator.factor"
          [min]="0"
          [max]="1"
          [step]="0.1"></p-slider>
        <p-colorPicker
          title="Maximum value color"
          class="text-center"
          appendTo="body"
          [(ngModel)]="tabular.colorGenerator.maxColor"></p-colorPicker>
      </div>
      <span class="mr-2"> Sync scrolling:</span>
      <p-checkbox [(ngModel)]="syncScrolling" [binary]="true" inputId="syncScrolling"></p-checkbox>
      <div class="flex items-center">
        <span class="ml-2 mr-2">Font size:</span>
        <p-slider
          title="Font Size stepper"
          class="w-[6rem] text-center mr-2"
          [(ngModel)]="tabular.fontSize"
          [min]="0"
          [max]="3"
          [step]="1"></p-slider>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" />
    </colgroup>
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        *ngFor="let col of columns; let index = index"
        [pSortableColumn]="col.field"
        pResizableColumn
        [title]="col.field"
        class="header-style text-sm"
        [ngClass]="{ highlight: col.index === tabular.xy[1] }">
        {{ col.header | truncate : 10 }}
        <p-sortIcon class="text-xs" [field]="col.field"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
    <tr>
      <ng-container *ngFor="let col of columns; let i = index">
        <ng-container [ngSwitch]="col.type">
          <ng-container *ngSwitchCase="'date'">
            <ng-container *ngIf="tabular.xy$ | async as xy">
              <td class="cell-style" [ngClass]="{ highlight: rowData['id'] === tabular.xy[0] }">
                <span class="text-sm text-slate-400 mr-2">{{ rowIndex + 1 | numberFormatter }}</span>
                <span class="font-semibold text-sm text-slate-600 bg-slate-100 py-1 px-2 rounded-md">
                  {{ rowData[col.field] | date : dateFormat }}
                </span>
              </td>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <td class="cell-style" [ngStyle]="tabular.getStyleColors(rowData[col.field], rowData['id'], i - 1)">
              {{ rowData[col.field] }}
            </td>
          </ng-container>
        </ng-container>
      </ng-container>
    </tr>
  </ng-template>

  <ng-template pTemplate="loadingbody" let-columns="columns">
    <tr>
      <td *ngFor="let col of columns; let even = even">
        <p-skeleton [ngStyle]="{ width: even ? (col.field === 'timestamp' ? '30%' : '40%') : '60%' }"></p-skeleton>
      </td>
    </tr>
  </ng-template>
</p-table>
