import { StationDto } from '@dtos/filters/station.dto';

export interface Payload {
  name: string;
  id: string;
}

export interface FiltersValue {
  classification: string[];
  stations: StationDto[];
  fuelTypes: string[];
  generators: string[];
  range: [string | Date, string | Date];
  frequency: string;
  regions: string[];
  region: string;
  interconnector: string;
  aggr_by_fuel_sources: boolean;
  fuelSources: string[];
}
