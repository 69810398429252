import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, of, shareReplay, switchMap } from 'rxjs';
import { StationDto, StationResponseDto } from '@dtos/filters/station.dto';
import { Frequency } from '@dtos/filters/frequency.dto';
import { CommonArgs } from '@dtos/common-args.dto';
import { environment } from '../../../../environments/environment';
import { DEFAULT_RANGE } from '@nemchart/services/date-formatter';

const BaseUrl = environment.api;

@Injectable()
export class FiltersService {
  set selectedFuelSources(val: string[]) {
    this._selectedFuelSources.next(val);
    this._selectedStations.next([]);
  }

  get selectedStations() {
    return this._selectedStations.getValue();
  }

  set selectedStations(val: StationDto[]) {
    this._selectedStations.next(val);
  }

  get selectedRegions() {
    return this._selectedRegions.getValue();
  }

  get selectedFuelSources() {
    return this._selectedFuelSources.getValue();
  }

  private _selectedFuelSources = new BehaviorSubject<string[]>([]);
  private _skipCallStations = false;
  private _selectedStations = new BehaviorSubject<StationDto[]>([]);
  private _selectedRegions = new BehaviorSubject<string[]>([]);

  aggrByFuelSources = true;

  constructor(private _http: HttpClient) {}

  commonArgs = new BehaviorSubject<CommonArgs>({
    frequency: Frequency.DAILY,
    range: DEFAULT_RANGE
  });

  updateCommonArgs(value: Record<string, unknown>) {
    const updated = {
      frequency: value['frequency'] as Frequency,
      range: value['range'] as [string, string]
    };

    this.commonArgs.next(updated);
  }

  fuelSourcesOptions$ = this._selectedRegions
    .pipe(
      switchMap(regions => {
        return regions?.length
          ? this._filterQuery({
              regions: regions
            })
          : of([]);
      })
    )
    .pipe(shareReplay());

  stationResponse$ = this._selectedFuelSources
    .pipe(
      switchMap(fuelSources => {
        return !this._skipCallStations && fuelSources?.length
          ? this._filterQuery<StationResponseDto>({
              regions: this._selectedRegions.getValue(),
              fuel_sources: fuelSources
            })
          : of({ stations: [] as StationDto[], stations_dates: null });
      })
    )
    .pipe(shareReplay());

  update(value: string[] | StationDto[] | boolean, model: string, skip_call?: boolean) {
    switch (model) {
      case 'regions':
        this._selectedRegions.next(value as string[]);
        this._selectedFuelSources.next([]);
        this._selectedStations.next([]);
        break;
      case 'stations':
        this._selectedStations.next(value as StationDto[]);
        break;
      case 'fuelSources':
        this._skipCallStations = !!skip_call;
        this._selectedFuelSources.next(value as string[]);
        break;
      case 'aggrByFuelSources':
        this.aggrByFuelSources = value as boolean;
        break;
    }
  }

  isSelected(name: string) {
    return this.selectedStations.map(st => st.name).indexOf(name) > -1;
  }

  private _filterQuery<T = string[]>(body: { regions: string[]; fuel_sources?: string[] }) {
    return this._http
      .post<T>(`${BaseUrl}/nem-generators/stations`, {
        regions: body.regions,
        fuel_sources: body.fuel_sources
      })
      .pipe(distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y)));
  }
}
