<nem-card>
  <p-panel [toggleable]="true" styleClass="nem-chart">
    <!-- Layout without tabular -->
    <ng-template pTemplate="header">
      <span class="panel-title text-sm" *ngIf="nemChart.value$ | async as value">
        {{ value.options?.title?.text }} - {{ nemChart.titleFrequency$ | async }}
        {{ nemChart.titleRegion$ | async }}
      </span>
      <div class="logo-placeholder"></div>
    </ng-template>

    <ng-template pTemplate="icons">
      <nem-chart-toolbar
        [chartTypeValue]="nemChart.chartType"
        (chartTypeValueChange)="chartTypeChange($event)"
        (toggleTableViewChange)="toggleTableView()"
        (exportToCSVChange)="nemChart.exportToCSV()"
        (dialogVisibleChange)="dialogVisible = $event"></nem-chart-toolbar>
    </ng-template>

    <ng-container *ngIf="!nemChart.tabular.tabularView">
      <ng-container *ngIf="!nemChart.loading && (nemChart.value$ | async) as value; else loaderTmp">
        <div class="w-full p-2" *ngIf="!dialogVisible">
          <nem-plot [options]="value.options" [hideLegend]="nemChart.hideLegend"> </nem-plot>
          <nem-plot [options]="nemChart.brush$ | async"> </nem-plot>
        </div>
      </ng-container>
    </ng-container>

    <!-- Layout with splitter and toggable tabular  -->
    <ng-container *ngIf="nemChart.tabular.tabularView">
      <p-splitter
        styleClass="min-h-[450px]"
        [panelSizes]="nemChart.tabular.panelSizes"
        (onResizeStart)="onResizeStart()"
        (onResizeEnd)="onResizeEnd()"
        layout="horizontal">
        <ng-template pTemplate>
          <div class="w-full m-auto">
            <div
              class="p-2"
              *ngIf="
                !dialogVisible && !resizing && !nemChart.loading && (nemChart.value$ | async) as value;
                else loaderTmp
              ">
              <nem-plot [options]="value.options" [hideLegend]="nemChart.hideLegend"> </nem-plot>
              <nem-plot [options]="nemChart.brush$ | async"> </nem-plot>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate>
          <nem-tabular
            class="w-full"
            [loading]="nemChart.loading"
            [tabular]="nemChart.tabular"
            [(syncScrolling)]="nemChart.syncWithTabularScrolling"
            [dateFormat]="nemChart.dateFormat"></nem-tabular>
        </ng-template>
      </p-splitter>
    </ng-container>
  </p-panel>
</nem-card>

<!-- Layout when dialog is maximized  -->
<ng-container *ngIf="dialogVisible && (nemChart.value$ | async) as value">
  <p-dialog
    [modal]="true"
    [attr.id]="nemChart.containerId"
    (onResizeEnd)="refresh()"
    (onMaximize)="refresh()"
    [(visible)]="dialogVisible"
    [maximizable]="false"
    [style]="{ width: '80vw' }">
    <ng-template pTemplate="header">
      <span>
        <span class="p-panel-header-icon capture-remove">
          <p-button
            styleClass="mr-2 p-button-rounded p-button-text snapshot-button"
            pTooltip="Capture snapshot"
            tooltipPosition="top"
            icon="pi pi-clone"
            [disabled]="capturePending"
            [loading]="capturePending"
            (onClick)="takeSnapshot(value.options?.title?.text, nemChart.containerId)">
          </p-button>
        </span>

        <span class="panel-title">
          {{ value.options?.title?.text }} - {{ nemChart.titleFrequency$ | async }} {{ nemChart.titleRegion$ | async }}
        </span>
      </span>
      <div class="logo-placeholder logo-placeholder-in-dialog right-[8rem]"></div>
    </ng-template>

    <nem-plot [options]="value.options" [hideLegend]="nemChart.hideLegend"> </nem-plot>
  </p-dialog>
</ng-container>

<!-- Template for loading  -->
<ng-template #loaderTmp>
  <div class="p-2">
    <nem-shimmer [text]="resizing ? 'Rendering' : 'Loading data'"></nem-shimmer>
  </div>
</ng-template>
