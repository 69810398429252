import html2canvas from 'html2canvas';

const SELECTORS_TO_REMOVE =
  '.capture-remove, .p-dialog-header-icons, .p-resizable-handle, .p-panel-header-icon, .apexcharts-yaxistooltip, .apexcharts-toolbar';

function formatURI(params: { text: string }) {
  return `vepc_capture_${params.text?.split(' ').join('_')}.png`;
}

function removeSelector(element: HTMLElement) {
  const elementsToRemove = element.querySelectorAll(SELECTORS_TO_REMOVE);
  elementsToRemove.forEach(el => {
    if (el.children.length) {
      el.childNodes.forEach(child => {
        if (child.nodeType === 1) {
          removeSelector(child as HTMLElement);
        }
      });
    }

    el.remove();
  });
}

export async function takeSnapshot(params: { containerId: string; text: string }) {
  try {
    const host = document.getElementById(params.containerId);
    if (!host) return;

    const chartContainer = host.querySelector('.p-dialog');
    if (!chartContainer) return;

    const canvas = await html2canvas(chartContainer as HTMLElement, {
      onclone: (doc, element) => {
        removeSelector(element);
      }
    });

    const url = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = url;
    link.download = formatURI(params);
    link.click();
    link.remove();
  } catch (error) {
    if (error) console.error(error);
  }
}
