import { Component, HostBinding, OnInit } from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'nem-root',
  template: `
    <p-messages *ngIf="authenticated"></p-messages>
    <nem-top-menu></nem-top-menu>
    <router-outlet></router-outlet>
  `
})
export class AppComponent implements OnInit {
  @HostBinding('class') classname = 'h-full';

  constructor(private config: PrimeNGConfig, private _authenticator: AuthenticatorService) {}

  get authenticated() {
    return this._authenticator.route == 'authenticated';
  }

  ngOnInit(): void {
    this.config.setTranslation({
      dateFormat: 'dd/mm/yy'
    });
  }
}
