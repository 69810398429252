import { Pipe, PipeTransform } from '@angular/core';
const regions: Record<string, string> = {
  NSW1: 'New South Wales',
  QLD1: 'Queensland',
  SA1: 'South Australia',
  TAS1: 'Tasmania',
  VIC1: 'Victoria'
};

@Pipe({
  name: 'region'
})
export class RegionPipe implements PipeTransform {
  transform(value: string) {
    return regions[value];
  }
}
