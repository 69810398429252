import { Component, HostBinding, Input } from '@angular/core';
import { ApexOptions } from 'ng-apexcharts';

@Component({
  selector: 'nem-plot',
  template: `
    <apx-chart
      *ngIf="options"
      [series]="$any(options.series)"
      [chart]="$any(options.chart)"
      [xaxis]="$any(options.xaxis)"
      [yaxis]="$any(options.yaxis)"
      [dataLabels]="$any(options.dataLabels)"
      [colors]="$any(options.colors)"
      [legend]="hideLegend ? undefined : $any(options.legend)"
      [stroke]="$any(options.stroke)"
      [tooltip]="$any(options.tooltip)"
      [markers]="$any(options.markers)"
      [fill]="$any(options.fill)">
    </apx-chart>
  `
})
export class NemPlotComponent {
  @HostBinding('class') classname = 'p-component';

  @Input() options: ApexOptions | null | undefined = null;

  @Input() hideLegend: boolean | null = null;
}
