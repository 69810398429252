import { Renderer2 } from '@angular/core';

export function fixScroll(evt: 'onshow' | 'onhide', renderer: Renderer2, name: string) {
  if (evt === 'onshow') {
    renderer.addClass(document.body, 'fix-scroll-' + name);
  }

  if (evt === 'onhide') {
    renderer.removeClass(document.body, 'fix-scroll-' + name);
  }
}

export function generateId(): string {
  let id = '';
  const characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < 10; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    id += characters.charAt(randomIndex);
  }

  return id;
}
