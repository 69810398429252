import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'nem-layout',
  templateUrl: './main-layout.comp.html'
})
export class MainLayoutComponent implements OnInit {
  constructor(private _authService: AuthService) {}

  ngOnInit(): void {
    this.updateContentClass();
  }

  authenticated$ = this._authService.authenticated$;

  @HostBinding('class') classname = 'w-full block';

  @Input() topSlot = true;

  @Input() contentClass: string[] = [];

  @Input() gridColumns: boolean | number = true;

  @Input() contentWithScroll = true;

  private updateContentClass() {
    const defaultClass = 'pt-6 px-6 grid gap-6 gap-y-9'.split(' ');
    const setClass = new Set(defaultClass);
    const classname = this.gridColumns
      ? `xl:grid-cols-${typeof this.gridColumns == 'number' ? this.gridColumns : 2} pb-24`
      : `xl:grid-cols-1`;
    setClass.add(classname);
    this.contentClass = [...setClass.values()];
  }
}
