import { Payload } from '@services/filters/filters';

export const REGIONS_PAYLOAD: Payload[] = [
  { name: 'New South Wales', id: 'NSW' },
  { name: 'Queensland', id: 'QLD' },
  { name: 'South Australia', id: 'SA' },
  { name: 'Tasmania', id: 'TAS' },
  { name: 'Victoria', id: 'VIC' }
];

export const INTERCONNECTORS_PAYLOAD: Payload[] = [
  { name: 'Terranora', id: 'N-Q-MNSP1' },
  { name: 'NSW_QLD', id: 'NSW1-QLD1' },
  { name: 'Basslink', id: 'T-V-MNSP1' },
  { name: 'Murraylink', id: 'V-S-MNSP1' },
  { name: 'Heywood', id: 'V-SA' },
  { name: 'VIC_NSW', id: 'VIC1-NSW1' }
];

export const FREQUENCY_PAYLOAD: Payload[] = [
  { name: '05 minutes', id: '5 minutes' },
  { name: '30 minutes', id: '30 minutes' },
  { name: 'Daily', id: '1 day' },
  { name: 'Weekly', id: '1 week' },
  { name: 'Monthly', id: '1 month' },
  { name: 'Quarterly', id: '3 month' },
  { name: 'Annually', id: '1 year' }
];

export const CHART_TYPES: (Payload & { icon: string[] })[] = [
  { name: 'Line', id: 'line', icon: ['pi-chart-line'] },
  { name: 'Line smooth', id: 'line-smooth', icon: ['pi-chart-line'] },
  { name: 'Area', id: 'area', icon: ['pi-chart-line'] },
  { name: 'Bar', id: 'bar', icon: ['pi-chart-bar'] }
];
