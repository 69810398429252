import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { FiltersValue, Payload } from '@services/filters/filters';
import { FiltersService } from '@services/filters/filters.service';
import { StationDto } from '@dtos/filters/station.dto';
import { Service } from '@constants/methods-services';
import { fixScroll } from '../../util';

// @ts-ignore
import DATE_RANGE_TEMPLATE from '@assets/tooltip-infos/date-range-info.html';
// @ts-ignore
import INTERCONNECTOR_INFO_TEMPLATE from '@assets/tooltip-infos/interconnectors-info.html';
// @ts-ignore
import NET_INTERCONNECTOR_INFO_TEMPLATE from '@assets/tooltip-infos/net-interconnectors-info.html';
import { FREQUENCY_PAYLOAD, INTERCONNECTORS_PAYLOAD, REGIONS_PAYLOAD } from '@constants/payloads';
import { updateRange } from '@nemchart/services/date-formatter';

@Component({
  selector: 'nem-filters',
  templateUrl: './filters.comp.html'
})
export class FiltersComponent {
  constructor(private _filterService: FiltersService, private _renderer: Renderer2) {}

  @Input() serviceName!: Service;

  @Input() filtersToShow = 'interconnectors,generators,frequency,regions,range';

  @Input() selectedInterconnectors: string[] = [];

  @Input() selectedGenerators: string[] = [];

  @Input() selectedRegion: string = 'VIC';

  @Input()
  set selectedRegions(val: string[]) {
    this._filterService.update(val, 'regions');
  }

  get selectedRegions() {
    return this._filterService.selectedRegions;
  }

  @Input()
  set selectedStations(val: StationDto[]) {
    this._filterService.update(val, 'stations');
  }

  get selectedStations() {
    return this._filterService.selectedStations;
  }

  @Input()
  set selectedFuelSources(val: string[]) {
    const skip_call = !this.filtersToShow.includes('advanced:full');
    this._filterService.update(val, 'fuelSources', skip_call);
  }

  get selectedFuelSources() {
    return this._filterService.selectedFuelSources;
  }

  @Input()
  set aggrByFuelSources(val: boolean) {
    this._filterService.update(val, 'aggrByFuelSources');
  }

  get aggrByFuelSources() {
    return this._filterService.aggrByFuelSources;
  }

  @Input() frequency!: string;

  @Input() range!: [string | Date, string | Date];

  @Input() loading: boolean = false;

  @Input() viewMode: 'single' | 'multiple' = 'single';

  @Output() filtersOnSubmit = new EventEmitter<Partial<FiltersValue>>();

  @Output() filtersOnChanges = new EventEmitter<{ model: string; value: Partial<FiltersValue> }>();

  get updatedFilters(): Partial<FiltersValue> {
    return {
      ...(this.filtersToShow.includes('interconnectors') ? { interconnectors: this.selectedInterconnectors } : {}),
      ...(this.filtersToShow.includes('regions') ? { regions: this.selectedRegions } : {}),
      ...(this.filtersToShow.includes('single_region') ? { region: this.selectedRegion } : {}),
      ...(this.filtersToShow.includes('frequency') ? { frequency: this.frequency } : {}),
      ...(this.filtersToShow.includes('range') ? { range: this.range } : {}),
      ...(this.filtersToShow.includes('generators') ? { generators: this.selectedGenerators } : {}),
      ...(this.filtersToShow.includes('advanced:full') ? { stations: this.selectedStations } : {}),
      ...(this.filtersToShow.includes('advanced') ? { fuel_sources: this.selectedFuelSources } : {}),
      ...(this.filtersToShow.includes('advanced:full') ? { aggr_by_fuel_sources: this.aggrByFuelSources } : {})
    };
  }

  regions = REGIONS_PAYLOAD;

  interConnectors = INTERCONNECTORS_PAYLOAD;

  dataFrequency = FREQUENCY_PAYLOAD;

  dateRangeInfo = DATE_RANGE_TEMPLATE;

  interconnectorsInfo = INTERCONNECTOR_INFO_TEMPLATE;

  netInterconnectorsInfo = NET_INTERCONNECTOR_INFO_TEMPLATE;

  modelOnChange(value: string | string[] | Date, model: string) {
    const updated = {
      ...this.updatedFilters,
      [model]: model === 'from' || model === 'to' ? updateRange(this.range, value as Date, model) : value
    };

    if (model === 'regions') {
      this._filterService.update(value as string[], model);
    }

    this.filtersOnChanges.emit({ model, value: updated });
    this._filterService.updateCommonArgs(updated);
  }

  filtersSubmit(ev: any) {
    if (ev) {
      this.filtersOnSubmit.emit(this.updatedFilters);
    }
  }

  fixbodyscroll(evt: 'onshow' | 'onhide', name: string) {
    fixScroll(evt, this._renderer, name);
  }
}
