import { Component, Input, Renderer2 } from '@angular/core';
import { FiltersService } from '@services/filters/filters.service';
import { fixScroll } from '../../util';
import { map } from 'rxjs';

// @ts-ignore
import STATIONS_DATE_TEMPLATE from '@assets/tooltip-infos/stations-date-info.html';

@Component({
  selector: 'nem-advanced-filters',
  templateUrl: './advanced-filters.comp.html'
})
export class AdvancedFiltersComponent {
  constructor(public filtersService: FiltersService, private _renderer: Renderer2) {}

  @Input() filtersToShow!: string;

  fuelSourcesOptions$ = this.filtersService.fuelSourcesOptions$;

  stationsDateInfo = STATIONS_DATE_TEMPLATE;

  stationOptions$ = this.filtersService.stationResponse$.pipe(map(res => res.stations));
  stationDates$ = this.filtersService.stationResponse$.pipe(map(res => res.stations_dates));

  modelOnChange(value: string[], model: string) {
    const skip_call = !this.filtersToShow.includes('advanced:full');
    this.filtersService.update(value, model, skip_call);
  }

  stationsOnChange({ originalEvent }: { originalEvent: Event }) {
    originalEvent.stopPropagation();
  }

  fixbodyscroll(evt: 'onshow' | 'onhide', name: string) {
    fixScroll(evt, this._renderer, name);
  }
}
